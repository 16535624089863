import { useEffect, useState } from "react";
import { Avatar, useThemeTokens, TextField, SelectOption, SelectMenu } from "@alphasights/alphadesign-components";
import { AlphaDesignTheme, AlphaDesignThemeContextProvider } from "@alphasights/alphadesign-components";
import { FLAG_MAP } from "../../constants/Country/flags";
import { COUNTRY_CODES } from "../../constants/Country/index.js";
import { x } from "@xstyled/styled-components";
import * as S from "./InputPhoneNumber.styled";

// moving usa and gb to the top of the list
export const COUNTRIES = COUNTRY_CODES.sort((a, b) => {
  const byOrder = a.order - b.order;
  if (byOrder === 0) {
    return a.name - b.name;
  } else {
    return byOrder;
  }
}).map((item, index) => {
  const flag = FLAG_MAP[item.isoCode];
  return {
    value: `${item.name}`,
    children: `+${item.code}`,
    isoCode: item.isoCode,
    countryCode: item.code,
    example: item.example,
    index,
    flag,
  };
});

const options = COUNTRIES.map((country) => {
  return <SelectOption key={country.index} {...country} img={country.flag} />;
});

export const InputPhoneNumber = ({
  value,
  onChange = () => {},
  showExtensionField = false,
  inputPhoneErrorMessage,
}) => {
  // allows passing only the phone number as initial value
  const phone = value && value.number ? value : { number: value };

  // separating country code from phone number
  const valueArray = phone && phone.number ? phone.number.trim().split(" ") : "";

  const countryCode = valueArray && valueArray.length > 1 ? valueArray[0].trim().replace("+", "") : null;

  if (valueArray) {
    valueArray.shift();
  }
  const { spacing } = useThemeTokens();
  const number = valueArray ? (valueArray.length === 1 ? valueArray[0] : valueArray.join(" ")) : "";

  const enteredCountry = COUNTRIES.find((country) => country.countryCode === countryCode);

  const [phoneNumber, setPhoneNumber] = useState(number);
  const [extNumber, setExtNumber] = useState(value ? value.extension : undefined);
  const [selectedCountry, setSelectedCountry] = useState(enteredCountry ? enteredCountry : COUNTRIES[0]);

  const renderSeletedItem = (selectedItems) => {
    const item = selectedItems[0];
    const country = COUNTRIES.find((country) => country.value === item.value);

    return (
      <x.div display="flex" alignItems="center">
        {country && country.flag && <Avatar src={country.flag} size="small" mr={spacing.layout.base} />}
        {country ? <div>{`+${country.countryCode}`}</div> : <div>{item.value}</div>}
      </x.div>
    );
  };

  const selectCountry = (value) => {
    const country = COUNTRIES.find((country) => country.value === value);
    setSelectedCountry(country);
  };

  useEffect(() => {
    if (!selectCountry || !phoneNumber) {
      onChange(undefined);
    } else {
      onChange({
        number: !selectCountry || !phoneNumber ? "" : `+${selectedCountry.countryCode} ${phoneNumber}`,
        extension: extNumber ? extNumber : "",
      });
    }
  }, [selectedCountry, phoneNumber, extNumber, onChange]);

  return (
    <AlphaDesignThemeContextProvider theme={AlphaDesignTheme.portal}>
      <x.div display="flex" flexDirection="column" w="100%" data-testid="input-phone-number-outer-wrapper">
        <x.div position="static" data-testid="input-phone-number-inner-wrapper">
          <x.div display="flex" data-testid="input-flex-wrapper">
            <x.div display="flex" flexDirection="row" gap="4px" flexGrow={1} data-testid="phone-input">
              <SelectMenu
                dataAttributes={{
                  "data-testid": "countrySelector",
                  "data-cy": "countrySelector",
                }}
                data-testid="countrySelector"
                maxWidth="205px"
                isClearButtonEnabled={false}
                customSelectedItemRendererInInput={renderSeletedItem}
                value={selectedCountry?.value}
                onChange={selectCountry}
              >
                {options}
              </SelectMenu>
              <TextField
                data-testid="phone-number-input"
                value={phoneNumber}
                onChange={(event) => {
                  setPhoneNumber(event.currentTarget.value);
                }}
                placeholder={selectedCountry?.example}
                error={!!inputPhoneErrorMessage}
              />
            </x.div>
            {showExtensionField && (
              <S.ExtensionFieldWrapper data-testid="extension-field-wrapper">
                <TextField
                  data-testid="extension-input"
                  value={extNumber}
                  placeholder="Ext."
                  pattern={/^[0-9]+$/}
                  onChange={(e) => setExtNumber(e.currentTarget.value)}
                />
              </S.ExtensionFieldWrapper>
            )}
          </x.div>
          {inputPhoneErrorMessage && <S.ErrorMessage>{inputPhoneErrorMessage}</S.ErrorMessage>}
        </x.div>
      </x.div>
    </AlphaDesignThemeContextProvider>
  );
};
