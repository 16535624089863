import styled from "styled-components";
import { th } from "@xstyled/styled-components";

export const ExtensionFieldWrapper = styled.div`
  width: 25%;
  margin-left: ${th.space("layout-base02")};
`;

export const ErrorMessage = styled.div`
  display: flex;
  color: ${th.color("text-danger")};
  font-size: ${th.fontSize("02")};
  margin-top: ${th.space("layout-base")};
`;
