import { filterOtherInteractions } from "components/InteractionsPage/helpers/Interaction";
import { Expert } from "types";

export const SURVEY_WARNING_THRESHOLD = 5;

const getMostRecent = (interaction: Interaction, currentInteraction: Interaction) => {
  if (interaction.requestedAt == null && currentInteraction.requestedAt == null) return interaction;
  else if (interaction.requestedAt == null && currentInteraction.requestedAt != null) return currentInteraction;
  else if (interaction.requestedAt != null && currentInteraction.requestedAt == null) return interaction;
  else if (new Date(currentInteraction.requestedAt) > new Date(interaction.requestedAt)) return currentInteraction;
  else return interaction;
};

interface ExpertPrecallStatus {
  id: string;
  hasInteractionOccurred: boolean;
}

export const interactionsToExperts = (interactions: Interaction[], portalMessagesEnabled: boolean = false) => {
  const allInteractions = [...interactions];
  const interactionsWithoutWorkRequests = allInteractions.filter(
    (interaction) => interaction.interactionType !== "Work Request"
  );

  // Determines if there have been any interactions in which schedule_call_time has already occurred, taking into account all the expert's interactions (not just calls).
  const expertsPreCallStatus = interactionsWithoutWorkRequests.reduce(
    (experts: ExpertPrecallStatus[], currentInteraction: Interaction) => {
      let expert = experts.find((expert) => expert.id === currentInteraction.advisorId);

      if (!expert) {
        let _expert = {
          id: currentInteraction.advisorId,
          hasInteractionOccurred: hasInteractionOccurred(currentInteraction),
        };
        experts.push(_expert);
      } else {
        expert.hasInteractionOccurred = expert.hasInteractionOccurred || hasInteractionOccurred(currentInteraction);
      }

      return experts;
    },
    []
  );

  const chainInteractions = filterOtherInteractions(interactions);

  const experts: Expert[] = chainInteractions.reduce((experts: Expert[], currentInteraction: Interaction) => {
    let expert = experts.find((expert) => expert.id === currentInteraction.advisorId);

    if (!expert) {
      let _expert = expertDataFromInteraction(currentInteraction);
      _expert.interactions = [currentInteraction];
      _expert.interaction = currentInteraction;
      _expert.hasInteractionOccurred =
        expertsPreCallStatus.find((expert) => expert.id === currentInteraction.advisorId)?.hasInteractionOccurred ||
        false;
      _expert.interactionCount = 1;
      experts.push(_expert);
    } else {
      expert.hostedInteraction = expert.hostedInteraction || currentInteraction.hostedInteraction;
      expert.hasCompletedInteractions = expert.hasCompletedInteractions || hasCompletedInteractions(currentInteraction);
      expert.hasOnlyCompletedInteractions =
        expert.hasOnlyCompletedInteractions && hasCompletedInteractions(currentInteraction);
      expert.canSendFollowUpMessage = expert.canSendFollowUpMessage || currentInteraction.canSendFollowUpMessage;
      expert.interaction = getMostRecent(expert.interaction, currentInteraction);
      expert.alphaCircleMultiplier = expert.interaction.alphaCircleMultiplier;
      expert.interactions.push(currentInteraction);
      expert.interactionCount++;
    }

    return experts;
  }, []);

  return portalMessagesEnabled ? experts : experts.filter((expert) => expert.canSendFollowUpMessage);
};

const expertDataFromInteraction = (interaction: Interaction): Expert => {
  return {
    id: interaction.advisorId,
    value: interaction.advisorId,
    label: interaction.advisorName,
    name: interaction.advisorName,
    noWrittenWork: interaction.noWrittenWork,
    relevantCompany: interaction.advisorCompany,
    relevantPosition: interaction.role,
    alphaCircleMultiplier: interaction.alphaCircleMultiplier,
    advisorLockState: interaction.advisorLockState,
    hostedInteraction: interaction.hostedInteraction,
    hasCompletedInteractions: hasCompletedInteractions(interaction),
    hasOnlyCompletedInteractions: hasCompletedInteractions(interaction),
    hasInteractionOccurred: hasInteractionOccurred(interaction),
    canSendFollowUpMessage: interaction.canSendFollowUpMessage,
    canSendMessage: interaction.canSendMessage,
    doubleBlinded: interaction.doubleBlinded,
    group: interaction.group,
    interactions: [interaction],
    interaction,
    interactionCount: 1,
  };
};

const hasCompletedInteractions = (interaction: Interaction) => interaction.state === "completed";

const hasInteractionOccurred = (interaction: Interaction) =>
  interaction.scheduledCallTime !== null &&
  interaction.scheduledCallTime !== undefined &&
  new Date(interaction.scheduledCallTime) < new Date();

export const getThreadStateColorFlyout = (state: any, colorTokens: any) => {
  if (!state) {
    return;
  }

  const colors = [
    { states: ["WAITING_RESPONSE"], color: colorTokens.text.danger },
    { states: ["REPLIED"], color: colorTokens.text.success },
    {
      states: ["PENDING_UPDATE", "AWAITING_APPROVAL"],
      color: colorTokens.text.processing,
    },
  ];

  return colors.find((color) => color.states.includes(state.code))?.color ?? colorTokens.text.secondary;
};
